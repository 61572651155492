import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { ReportService } from 'src/app/services/report/report.service';
import { ConfirmationWindowComponent } from '../../common-module/confirmation-window/confirmation-window.component';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {
  form:FormGroup
  approvalID:any
  approvalType:any;
  approve:any=false
  reject:any=false
  urlForPdf:any
  loader:any=false
  IspreviewORNot:any=false
  constructor(private dialogref:MatDialogRef<ReportViewerComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private fb:FormBuilder,private dialogs:MatDialog,private report:ReportService,private admin:AdministrationService) { 
    this.form = this.fb.group({})
  }
 
  ngOnInit(): void {
    if(this.data['purpose'] == 'approve'){
     
      this.approvalID=this.data['data']['approvalID']
      this.approvalType=this.data['data']['approvalType']
      this.checkReportStatus( this.approvalID)
      
    }
    if(this.data['purpose'] == 'preview'){
      this.IspreviewORNot=true
       this.urlForPdf=this.data['data']['reviewedDoc']
      this.previewPDF(this.data['data']['reviewedDoc'])
     
     
    }
   
  }
checkReportStatus(approvalID:any){
  this.loader=true
  this.report.checkreportStatus(approvalID).subscribe(res=>{
    this.loader=false
    if(res.isSuccess){
      
      this.approvalType=res.result == 0 ? 0 : 1
      this.IspreviewORNot=this.approvalType == 0
       this.displayPdf()
     
      
    }
  })
}
  displayPdf(){
    this.loader=true
    this.report.displayPDF( this.approvalID,this.approvalType).subscribe(res=>{
      this.loader=false
      if(res.isSuccess){
      this.previewPDF(res.result)
       
        
      }
    })
  }
  close(){
this.dialogref.close()
  }
  rejectNotification(){
    const dialogRef = this.dialogs.open(ConfirmationWindowComponent, {
      width: '440px',
      height: '309px',
      position: { top: '50vh',
      left: '50vw',
     },
      data:{heading:'Please enter your Password to Reject the Report',subHeading:'',
      data: {}},
      panelClass:'confirm-passwordDialog'
    });
  
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        
        let data={
          approvalID:this.approvalID,
          approvalStatus:2
        }
  this.reject=true
        this.report.approveORrejectNotification(data).subscribe(res=>{
          this.reject=false
          if(res.isSuccess){
            this.admin.snackBarDialogBox(false, "Report request Rejected")
            this.dialogref.close(true)
          }
         
        
        })
  
        
      }
    });
  }
  approveNotification(){
    const dialogRef = this.dialogs.open(ConfirmationWindowComponent, {
      width: '440px',
      height: '309px',
      position: { top: '50vh',
      left: '50vw',
     },
      data:{heading:'Please enter your Password to Approve the Report',subHeading:'',
      data: {}},
      panelClass:'confirm-passwordDialog'
    });
  
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
       


        let data={
          approvalID:this.approvalID,
          approvalStatus:1
        }
       this.approve=true
        this.report.approveORrejectNotification(data).subscribe(res=>{
          this.approve=false
          if(res.isSuccess){
            this.admin.snackBarDialogBox(true, "Report request Approved successfully")
            this.dialogref.close(true)
          }
         
        
        })
  
        
      }
    });
  }
  previewPDF(res:any){
    let byteChar = window.atob(res);
    let byteArray = new Array(byteChar.length);
    for(let i = 0; i < byteChar.length; i++){
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    let blob = new Blob([uIntArray], {type : 'application/pdf'});
    let url = window.URL.createObjectURL(blob);
    let root=<HTMLIFrameElement>document.querySelector("iframe");
     root.src=url
  }
  downloadpdfReport(){
    let byteChar = window.atob(this.urlForPdf);
    let byteArray = new Array(byteChar.length);
    for(let i = 0; i < byteChar.length; i++){
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    let blob = new Blob([uIntArray], {type : 'application/pdf'});
    let url = window.URL.createObjectURL(blob);
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download =this.data['data']['reportType'] + '_report.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.open(url, '_blank');
  }
}
