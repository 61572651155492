
<div class="col-md-12 notification_heading">
<div class="col-md-6">Notifications
     <!-- <div class="notf_count"><div>5</div></div> -->
</div>
<div class="col-md-6 close_icon"><img src="assets/icons/close.svg" (click)="close()"></div>
</div>
    
<div class="row notification_window" >
    <mat-card id="notification_view">    
   
    <form [formGroup]="form" >
        <div class="create_divs" infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="selector" [fromRoot]="true"
        (scrolled)="onScroll()">

            <div class="col-md-12 notification_row" *ngFor="let item of listOfNotifications;let i=index" [ngStyle]="{'border-bottom': i == listOfNotifications.length - 1 ? 'none' : '1px solid #E0E0E0'}">
                <div class="row_img_div">
                <img src="assets/icons/{{getImage(item.notificationCategory)}}.svg" *ngIf="getImage(item.notificationCategory) != ''" class="row_img">
                </div>
                <div class="col-md-9" (click)="item.notificationCategory == 'ReportApprovals' ? viewDetailTable(item.subNotifications,item.notificationCategory,item.createdBy) : ''" [ngStyle]="{'cursor':item.notificationCategory == 'ReportApprovals' ? 'pointer' : ''}">
                    <div class="col-md-12">{{item.title}}</div>
                    <div class="col-md-12">{{item.date}}</div>
                </div>
                <div class="col-md-2 view_all" *ngIf="item.subNotifications.length > 1" (click)="viewDetailTable(item.subNotifications,item.notificationCategory,item.createdBy)">{{actionLoad(item.notificationCategory)}}</div>
                </div>
                <div class="no-data" *ngIf="noData">No Notifications to display</div>
                <!-- <div class="row col-md-12" class="loaders" *ngIf="loader"> <img src="assets/icons/loading.gif" ></div> -->
                <div class="loader_ngx"  *ngIf="loader">
                <ngx-skeleton-loader class="sklton" [theme]="{ 
                    'border-radius': '5px',
                     height: '7%',            
                     border: '1px solid white'
                   
                  }" appearance="line" count="8"></ngx-skeleton-loader>
                </div>
               
        </div>
    </form>
    </mat-card>
</div>
      
