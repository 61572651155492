import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { routes } from './app.router';
import { MaterialModule } from './material.module';
import { BlankComponent } from './components/layouts/blank/blank.component';
import { FullComponent } from './components/layouts/full/full.component';
import { HeaderComponent } from './components/layouts/full/header/header.component';
import { SidebarComponent } from './components/layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { CookieModule } from 'ngx-cookie';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlankComponent,
    FullComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules }),
    MaterialModule,
    BrowserAnimationsModule, 
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    ReactiveFormsModule,   
    CommonModule,
    NgxSkeletonLoaderModule, 
    CookieModule.withOptions()
    
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
