<div style="display: contents;" *ngIf="collapsez">
    <div class="row col-md-12 sidebar_logo" #side>
        <img src="assets/icons/alpha_tech.png" class="logopng">
        <img src="assets/icons/menu.svg" class="menusvg" (click)="collapse()">
    </div>
    <div class="row col-md-12 sub_container" id="submenuPanel" *ngIf="showORhidesubmenu" [ngStyle]="{'height':height+ 'px'}">
        <span class="sidebar_submenu"><mat-icon (click)="closematNav()">arrow_back</mat-icon> {{submenupanelHead}}</span>
        <div class="col-md-11 sublisting" *ngFor="let item of submenuList" (click)="opensubmenu()" [routerLink]="[item.link]">{{item.menuName}}</div>
    </div> 
    <!-- <div #menuTrigger="matMenuTrigger"  [matMenuTriggerFor]="menu" style="display: none;"></div> -->
   
    <div class="sidebarBG" id="sidesNav" style="padding: 10px 2px 4px 2px;" *ngIf="loader">
        <ngx-skeleton-loader class="sklton"   [theme]="{ 
            'border-radius': '4px',
             height: '5%',            
             border: '0px solid white'
             
             
           
          }" appearance="line" count="10"></ngx-skeleton-loader>
    </div>
    <div class="sidebarBG" id="sidesNav" *ngIf="!loader">
        
    <mat-nav-list class="">
        <mat-list-item  
              
            *ngFor="let menuitem of menuItems;let i=index;"                    
            (click)="menuitem.subMenu.length == 0 ? loadsubmenusselection(menuitem,i) : loadsubmenus(menuitem,i)"
            [ngStyle]="{'display': menuitem.link == null && menuitem.subMenu.length == 0 ? 'none' : '','height':menuitem.menuName.length > 19 ? '52px':''}" 
            [routerLink]="menuitem.link != null ? [menuitem.link] : []"
            >
            <a
                class=""               
                
                      
            >
              
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="{{ menuitem.icon }}" *ngIf="selectedMenu != i"
                />
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="{{ menuitem.iconSelected }}" *ngIf="selectedMenu == i"
                />
                <span class="menu_name" [ngStyle]="{'color':selectedMenu == i  ? 'white' :'','margin-top':menuitem.menuName.length > 19 ? '15px':''}">{{ menuitem.menuName }}</span>
                <!-- <span class="beta" *ngIf="menuitem.name === 'Attendance'">Beta</span> -->
                <span fxFlex></span>
                
            </a>    
           
        </mat-list-item>
        
       
    
    </mat-nav-list>
    
    <!-- [routerLink]="menuitem.state"      -->
    <mat-nav-list class="logoutNav">
        <mat-list-item           
                  
            (click)="logout()" >
            <a
                class=""               
                
                      
            >
              
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="assets/icons/logout_default.svg"
                />
                <span class="menu_name">Log Out</span>
                <!-- <span class="beta" *ngIf="menuitem.name === 'Attendance'">Beta</span> -->
                <span fxFlex></span>
                
            </a>    
           
        </mat-list-item>
        </mat-nav-list>

    
</div>
</div>



<div style="display: contents;" *ngIf="!collapsez" [ngClass]="'collapseMode'">
    <div class="row col-md-12 sidebar_logo sidebar_logos" #side>
        <img src="assets/icons/logo_closed.svg" class="logopngCollapse">
        <img src="assets/icons/menu.svg" class="menusvgCollapse" (click)="collapse()">
    </div>
    <div class="row col-md-12 sub_container" *ngIf="showORhidesubmenu" [ngStyle]="{'height':height+ 'px'}">
        <span class="sidebar_submenu"><mat-icon (click)="closematNav()">arrow_back</mat-icon> {{submenupanelHead}}</span>
        <div class="col-md-11 sublisting" *ngFor="let item of submenuList" (click)="showORhidesubmenu=false" [routerLink]="[item.link]">{{item.menuName}}</div>
    </div> 
    <div class="sidebarBG" id="sidesNav">
    <mat-nav-list class="">
        <mat-list-item   
              
            *ngFor="let menuitem of menuItems;let i=index;"          
            (click)="menuitem.subMenu.length == 0 ? loadsubmenusselection(menuitem,i) : loadsubmenus(menuitem,i)"
            [ngStyle]="{'display': menuitem.link == null && menuitem.subMenu.length == 0 ? 'none' : '','height':menuitem.menuName.length > 19 ? '52px':''}" 
            [routerLink]="menuitem.link != null ? [menuitem.link] : []"
            >
            
            <a
                class=""               
                
                  
            >
              
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="{{ menuitem.icon }}" *ngIf="selectedMenu != i"
                />
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="{{ menuitem.iconSelected }}" *ngIf="selectedMenu == i"
                />
                <!-- <span class="menu_name" [ngStyle]="{'color':selectedMenu == i  ? 'white' :''}">{{ menuitem.name }}</span> -->
                <!-- <span class="beta" *ngIf="menuitem.name === 'Attendance'">Beta</span> -->
                <span fxFlex></span>
                
            </a>    
           
        </mat-list-item>
       
   
    </mat-nav-list>
    
    <!-- [routerLink]="menuitem.state"      -->
    <mat-nav-list class="logoutNav">
        <mat-list-item           
                  
            (click)="logout()" >
            <a
                class=""               
                
                      
            >
              
                <img
                    class=""
                    [ngStyle]="{ 'margin-right': '10px', position: 'relative', width: '21px', right: '3px' }"
                    
                    src="assets/icons/logout_default.svg"
                />
                <!-- <span class="menu_name">Log Out</span> -->
                <!-- <span class="beta" *ngIf="menuitem.name === 'Attendance'">Beta</span> -->
                <span fxFlex></span>
                
            </a>    
           
        </mat-list-item>
        </mat-nav-list>

    
</div>
</div>