import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { HttpService } from '../http/http.service';
import { WeatherForecast } from '../../models/weatherforecast.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpService,
  ) {}
  
 
  getuserLogin(data:any): Observable<any> {
    return this.http.post<any>('api/auth/login',data).pipe(map((res) => res));
  } 
  signout(): Observable<any> {
    let data={

    }
    return this.http.post<any>('api/auth/signout',data).pipe(map((res) => res));
  }
  getMenus(user_id:any): Observable<any> {
    return this.http.get<any>(`api/user/permissions`).pipe(map((res) => res));
  }
  forgotPassword(email:any): Observable<any> {
    return this.http.get<any>(`api/user/send-email?email=${email}`).pipe(map((res) => res));
  }
}
