import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { ViewNotificationComponent } from '../../home/dashboard-module/view-notification/view-notification.component';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  dir = 'ltr';
  collapses:any;
  mobileviewenabled:any=false;
  root =<HTMLElement> document.querySelector(':root');
  pageInfoData:any;
   height:any
   RoleName:any;
   RoleNameShort:any
   emailId:any;
   NotificationCount:any=0
  constructor(private admin:AdministrationService,private elRef: ElementRef,private _cookieService:CookieService,private dialog:MatDialog,private cdr:ChangeDetectorRef,private router:Router) { }

  ngOnInit(): void {
    this.admin.getpageInfo().subscribe(data=>{
      this.pageInfoData=data;
      this._cookieService.put('menuName',this.pageInfoData['menuIndex'])
    })
    this.root.style.setProperty('--visibiltys', 'none');

    var vElement = document.querySelector("#snav")as HTMLElement;
      
       this.height = vElement.offsetHeight;


       switch(this._cookieService.get('roleId')) { 
        case '1': { 
          this.RoleName='Admin'
          this.RoleNameShort='AD'
          this.emailId=this._cookieService.get('email') != null ? this._cookieService.get('email') :''
           break; 
        } 
        case '2': { 
          this.RoleName='Operator'
          this.RoleNameShort='OP'
          this.emailId=this._cookieService.get('email') != null ? this._cookieService.get('email') :''
           break; 
        } 
        case '3': { 
          this.RoleName='User'
          this.RoleNameShort='US'
          this.emailId=this._cookieService.get('email') != null ? this._cookieService.get('email') :''
          break; 
       } 
       case '4': { 
        this.RoleName='Supervisor'
        this.RoleNameShort='SP'
        this.emailId=this._cookieService.get('email') != null ? this._cookieService.get('email') :''
        break; 
     } 
        default: { 
           
           break; 
        } 
     } 

this.loadNotification();

this.admin.Notificationcounts.subscribe(res=>{
  
     this.NotificationCount=res
     this.cdr.detectChanges()
  
})


      }
      loadNotification(){
        this.admin.getnotificationCount().subscribe(res=>{
          if(res.isSuccess){
             this.NotificationCount=res.result
          }
        })
      }
  collapse(eve:any){
    if(eve=='mobenabled'){
      this.root.style.setProperty('--visibiltys', 'none');
    }
    else{
      this.collapses=eve;
    }
   
  }
  collapsed(){
    this.root.style.setProperty('--visibiltys', 'block');
    this.mobileviewenabled=true;
    var vElement = document.querySelector("#snav")as HTMLElement;
      
    this.height = vElement.offsetHeight;
            
  }
  openNotification(){
    const dialogRef = this.dialog.open(ViewNotificationComponent, {
      width: '545px',
      position: { right: '0' },
      data: {},
      panelClass:'notification_window'
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {       
        
      }
    });
  }
  changePassword(){
    this.router.navigate(['authentication'], {queryParams:{token:'changePassword'}} )

  }
}
