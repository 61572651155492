<div
    class="main-container" id="fullcmp" [ngClass]="collapses == true ? 'collpse' : 'collpsez'">

   <mat-sidenav-container class="example-container">
   
    <mat-sidenav opened mode="side" #snav
    id="snav"
    class="pl-xs"> <app-sidebar (collapsed)="collapse($event)" [mobileviewenabled]="mobileviewenabled" [index]="pageInfoData ? pageInfoData['menuIndex']:0" [height]="height"></app-sidebar></mat-sidenav>

    <!-- <mat-sidenav opened mode="side" position="end">End content</mat-sidenav> -->
    <mat-sidenav-content class="page-wrapper">
        <div class="topBar">
        <mat-toolbar color="" class="topbar telative" style="justify-content: space-between">
            <div class="row col-md-12">
               <div  class="col-md-1 col-sm-2 mobicon"> <img src="assets/icons/menu.svg" class="menusvg" (click)="collapsed()"></div>
                <div class="col-md-6">
                    <span class="full_head matTooltipText" *ngIf="pageInfoData"  [matTooltip]="pageInfoData['heading']"  matTooltipClass="full_tooltip">{{pageInfoData['heading']}}</span>
                    <span class="sub_head matTooltipText" *ngIf="pageInfoData" [matTooltip]="pageInfoData['subheading_2']" matTooltipClass="full_tooltip">{{pageInfoData['subheading']}} <span class="subheading_2" >{{pageInfoData['subheading_2']}}</span></span>
                </div> <div class="col-max-6 notIcon"><img src="assets/icons/notification.svg" (click)="openNotification()" class="notf_icon" [ngStyle]="{'margin-right': NotificationCount == 0 ? '6px' : '-14px'}">
                    <div class="notf_count_header" *ngIf="NotificationCount != 0"><div>{{NotificationCount}}</div></div>
                <div class="avatar" [matMenuTriggerFor]="menu">{{RoleNameShort}}</div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="respnse_view">{{RoleName}}</button>
                    <button mat-menu-item class="respnse_view">{{emailId}}</button>
                    <button mat-menu-item (click)="changePassword()">Change Password</button>
                  </mat-menu>
                
                <div class="roleANDEmail">{{RoleName}} <span class="roleANDEmail_mail">{{emailId}}</span></div>
                </div>
            </div>
        </mat-toolbar>
    </div>
            <div class="page-content">
                <router-outlet>
                  
                </router-outlet>
            </div>
    </mat-sidenav-content>  
</mat-sidenav-container>
</div>