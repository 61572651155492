import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ReportService } from 'src/app/services/report/report.service';
import { ReportViewerComponent } from '../../report-module/report-viewer/report-viewer.component';

interface listData {
  notificationCategory: any;
  title: any;
  date: any;
  subNotifications: any;
  reportType: any;
  notificationID:any;
  createdBy:any
}

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {
form:FormGroup
noData:any=false;
loader:any=false
listOfNotifications:any=[]
selector: string = '.create_divs';
pageSize:any=10
pageIndex:any=1
  constructor(private fb:FormBuilder,private dialogRef:MatDialogRef<ViewNotificationComponent>,private router:Router,private dashboard:DashboardService,private dialog:MatDialog,private admin:AdministrationService,private report:ReportService) {
    this.form = this.fb.group({ 
     
      
      })
   }

  ngOnInit(): void {


  this.loadAllNotification(this.pageIndex,this.pageSize)
  }

  loadAllNotification(pageIndex:any,pageSize:any){
    this.loader=true
this.noData=false
this.dashboard.ListAllNotifications(pageIndex,pageSize).subscribe(res=>{
  this.loader=false
  if(res.isSuccess){
    
    this.noData=res.result.length == 0
res.result.forEach((element:any) => {
  
  if(this.listOfNotifications.length == 0){
    let obj:listData={
      notificationCategory:element.notificationType,
      title:element.title,
      date:moment(element.notificationDate).format("DD-MMM-yyyy") + ', '+ moment(element.notificationDate).format("HH:mm"),
      subNotifications:[],     
      reportType:element.notificationData != null ? element.notificationData.ReportType : null,
      notificationID:element.notificationID,
      createdBy:element.createdBy
      
    }
    if(element.notificationData != null){
      obj.subNotifications.push(element.notificationData)
    }
    this.listOfNotifications.push(obj)
    this.listOfNotifications= _.uniqBy(this.listOfNotifications, 'notificationID');
  }
  else{
   
    let index = -1
    

    this.listOfNotifications.forEach((elements:any,indexs:any) => {
      
      if(elements.reportType != null && element.notificationData !=null){
        if(elements.reportType == element.notificationData.ReportType && elements.createdBy == element.createdBy){
           index=indexs
        }
      }
    });

    
      if(index != -1){

       
      //   slNo:'',
      //   date:moment(element.activityDate).format("DD-MMM-YYYY"),
      //   time:moment(element.activityDate).format("HH:mm"),
      //   username:element.userName,
      //   ipaddress:element.ipAddress,
      //   parameter:element.propertyName,
      //   oldvalue:element.originalValue,
      //   newvalue:element.currentValue,
      //   comment:'',
      //   fulldate:element.activityDate,
      //   subMenus:[]
       
      // }
        this.listOfNotifications[index].subNotifications.push(element.notificationData)
        this.listOfNotifications[index].subNotifications= _.uniqBy( this.listOfNotifications[index].subNotifications, 'ReferenceID');
       
        
      // this.listOfNotifications.push(obj)
      }
      else{
        let obj:listData={
          notificationCategory:element.notificationType,
          title:element.title,
          date:moment(element.notificationDate).format("DD-MMM-yyyy") + ', '+ moment(element.notificationDate).format("HH:mm"),
          subNotifications:[],     
          reportType:element.notificationData != null ? element.notificationData.ReportType : null,
          notificationID:element.notificationID,
          createdBy:element.createdBy
        }
        if(element.notificationData != null){
          obj.subNotifications.push(element.notificationData)
        }
       
       
        
        this.listOfNotifications.push(obj)
        this.listOfNotifications= _.uniqBy(this.listOfNotifications, 'notificationID');
      }


   


  }




  
 
});

   if(res.pagination.totalRecords > this.pageSize) {
if(this.listOfNotifications.length < 10){
  let count=this.pageSize - this.listOfNotifications.length
  this.pageSize = this.pageSize + count
  this. loadAllNotification(this.pageIndex,this.pageSize)
}
   }

  }
})

this.deactivateLoadedNotification();
  }

  deactivateLoadedNotification(){
this.dashboard.deactivatereadNotifications().subscribe(res=>{
if(res.isSuccess){
  this.admin.sendNotifdicationCount(0);
}
})

  }
close(){
  this.dialogRef.close(false)
}

openDatalog(data:any){
   let obj={
    // reviewedDoc:data[0].pdfDoc,
    approvalID:data[0].ReferenceID,
    approvalType:0
   }
    
  const dialogRef = this.dialog.open(ReportViewerComponent, {
    width: '845px',
    position: { right: '0' },
    data:{data:obj,purpose:'approve'},
    panelClass:'list-approve_requests'
  });

  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
       this.pageIndex=1;
       this.pageSize=10;
      this.loadAllNotification(this.pageIndex,this.pageSize)
      
    }
  });



}
viewDetailTable(data:any,type:any,userId:any){
  if(type == 'ReportApprovals'){
    this.checkAlreadyApproved(data,type,userId)
//     switch(data[0].ReportType){
//       case 'Datalog':{
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
     
//        break;
//       }
//       case 'Alarmlog':{
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
     
//        break;
//       }
//       case 'AlarmlogAuditTrail':{
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
     
//        break;
//       }
//       case 'EquipmentAuditTrail':{
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
     
//        break;
//       }
//       case 'UserAuditTrail':{
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
     
//        break;
//       }

//       case 'Userlog':{
//        let result:any= this.checkAlreadyApproved('Userlog',0,userId)
//        if(result == 0){
// this.admin.snackBarDialogBox(false,'Nothing for Approvals')
//        }
//        if(result > 0){
//         if(data.length == 1){

//           this.openDatalog(data)
//         }
//         else{
//           this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
//           this.dialogRef.close(false)
//         }
//        }
       
     
//        break;
//       }
   
//       default:{

//       break;

//    }
          
  
   
//     }
  }
  // if(type == ''){
  //   switch(type){
  //     case 'Approved':{
       
  //      break;
  //     }
   
          
  //  case 'ReportApprovals':
  //    {
   
  //      break;
  //     }
   
  //     case 'Rejected':
  //      {
   
  //        break;
  //       }
   
  //   }
  // }
  
}
getImage(type:any){
 switch(type){
   case 'Approved':{
    return 'approved'
    
   }

       
case 'ReportApprovals':
  {
    return 'review'
    
   }

   case 'Rejected':
    {
      return 'rejected'
      
     }
default:{
  return ''
}
 }



}
actionLoad(type:any){
  switch(type){
   
 
        
 case 'ReportApprovals':
   {
     return 'View all'
     
    }
 
   
 default:{
   return ''
 }
  }
}
onScroll(){
  this.pageSize=this.pageSize + 3
  this. loadAllNotification(this.pageIndex,this.pageSize)
}
checkAlreadyApproved(data:any,type:any,userId:any){
  this.report.loadAllNotification(10,1,data[0].ReportType,0,userId).subscribe((res:any)=>{
 if(res.isSuccess){
 
  if(res.result.length == 0) {
    this.admin.snackBarDialogBox(false, 'All of them have already been processed! No pendings.' )
  }
  else{
    switch(data[0].ReportType){
      case 'Datalog':{
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
     
       break;
      }
      case 'Alarmlog':{
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
     
       break;
      }
      case 'AlarmlogAuditTrail':{
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
     
       break;
      }
      case 'EquipmentAuditTrail':{
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
     
       break;
      }
      case 'UserAuditTrail':{
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
     
       break;
      }
  
      case 'Userlog':{
       
        if(data.length == 1){
  
          this.openDatalog(data)
        }
        else{
          this.router.navigate(['home/report/approve-request'], {queryParams:{userId:userId,report:data[0].ReportType}} )
          this.dialogRef.close(false)
        }
       
       
     
       break;
      }
   
      default:{
  
      break;
  
   }
          
  
   
    }
  }
 
 }
 
  })
}
}
