<div class="col-md-12 confirm_password_heading">Confirm your Credentials</div>
<div class="row" id="confirm_password">
    <mat-card>
        <form [formGroup]="form" >
            <div class="col-md-12 p-l-r user_margin head_box">{{data['heading']}} <span>{{ data['subHeading'] != '' ? ('"'+ data['subHeading'] + '"') : ''}}.</span></div>
            <div class="col-md-12 p-l-r user_margin"  >
                <mat-form-field appearance="outline" >
                    <mat-label>Password</mat-label>
                    <input
                        matInput                                                        
                        formControlName="password"
                        required
                        minlength="3"
                        tabindex="-1"
                        [type]="hide ? 'password' : 'text' "  
                        autocomplete="new-password"
                    />
                    <span
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
        >
            <mat-icon class="eye_icon">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </span>
                </mat-form-field>
               
              </div>
              <div class="col-md-12 p-l-r user_margin"  >
                <mat-form-field appearance="outline" >
                    <mat-label>Remarks</mat-label>
                    <textarea 
                    matInput                                                       
                        formControlName="remarks"
                        required
                        minlength="10"
                        tabindex="-1"
                        
                    >
                    </textarea>
                  
                </mat-form-field>
                <div class="col-md-12 p-l-r user_margin head_box2">Min 10 Characters</div>
              </div>
              

              <div class="row col-md-12 p-l-r confirm_btn">
                <div class="col-md-7 btn_form">
        
                </div>
                <div class="col-md-3 cancel_outer">
                    <button class="cancel" (click)="closewindow()">Cancel</button>
                </div>
                <div class="col-md-2 save_outer">
                    <button class="save" (click)="save()" [disabled]="!form.valid">Confirm</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>