import { Routes } from '@angular/router';
import { LoginComponent } from './components/authentication/login/login.component';
import { FullComponent } from './components/layouts/full/full.component';
import { AuthGuard } from './guards/auth/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'authentication',
    },
    
  {
    path: 'home',
    component: FullComponent,
    canActivate: [AuthGuard],
     children: [
       {
         path: '',
         loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)

       },
     ],
  },
 

  {
    path: 'authentication',
    children: [
     
      {
        path: '',
        loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/404',
  },
  
];
