import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  data:any={}
  constructor(private http:HttpService) { }
  ListAllNotifications(pageNo:any,pageSize:any): Observable<any> {
    return this.http.get<any>(`api/notifications/list?EnablePagination=true&PageNo=${pageNo}&PageSize=${pageSize}`).pipe(map((res) => res));
  }
  
  deactivatereadNotifications(): Observable<any> {
    return this.http.put<any>('api/notifications/read',this.data).pipe(map((res) => res));
  }
  logsummary(): Observable<any> {
    return this.http.get<any>('api/dashboard/log-summary').pipe(map((res) => res));
  }
  loadGraphsData(id:any): Observable<any> {
    return this.http.get<any>(`api/dashboard/graph?equipmentId=${id}`).pipe(map((res) => res));
  }
  loadChhanelWiseData(id:any): Observable<any> {
    return this.http.get<any>(`api/dashboard/channel-values?equipmentId=${id}`).pipe(map((res) => res));
  }
}
