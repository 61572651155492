import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public baseUrl;
 

  constructor( @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;    
  }
}

