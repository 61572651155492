import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http:HttpService) { }
  getDatalogReports(data:any): Observable<any> {
    return this.http.post<any>('api/reports/datalog/view',data).pipe(map((res) => res));
  }
  
  downloadReport(data:any): Observable<any> {
    return this.http.post<any>('api/utility/datalog/report',data).pipe(map((res) => res));
  }
  downloadGraphReport(data:any): Observable<any> {
    return this.http.post<any>('api/utility/datalog/graph',data).pipe(map((res) => res));
  }
  getDatalogMKT(data:any): Observable<any> {
    return this.http.post<any>('api/reports/datalog/mkt/view',data).pipe(map((res) => res));
  }
 
  downloadMKTReport(data:any): Observable<any> {
    return this.http.post<any>('api/utility/datalog/mkt/report',data).pipe(map((res) => res));
  }
  LoadUserlogs(data:any): Observable<any> {
    return this.http.post<any>('api/reports/userlog',data).pipe(map((res) => res));
  }
  LoadUserAuditTrail(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/user',data).pipe(map((res) => res));
  }
  LoadEquipmentAuditTrail(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/equipment',data).pipe(map((res) => res));
  }
  getAlarmDatalog(data:any): Observable<any> {
    return this.http.post<any>('api/reports/alarmlog',data).pipe(map((res) => res));
  }
  
  getAcknowledged(data:any): Observable<any> {
    return this.http.post<any>('api/reports/alarmlog/acknowledge',data).pipe(map((res) => res));
  }
  listOfAlerts(): Observable<any> {
    return this.http.get<any>('api/alerts?EnablePagination=false').pipe(map((res) => res));
  }
  
  getAlarmAudittraillog(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/alarmlog',data).pipe(map((res) => res));
  }
  getEmaillAudittrail(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/email',data).pipe(map((res) => res));
  }
  getsmsDatalog(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/sms',data).pipe(map((res) => res));
  }
  checkIsReviewer(id:any): Observable<any> {
    return this.http.get<any>(`api/user/${id}`).pipe(map((res) => res));
  }
  sendForApproval(data:any): Observable<any> {
    return this.http.post<any>('api/reports/approval/datalog',data).pipe(map((res) => res));
  }
  loadAllNotification(pageSize:any,pageIndex:any,reportType:any,approvalStatus:any,reviewerId:any): Observable<any> {
    return this.http.get<any>(`api/signedreports/list?reportType=${reportType}&approvalStatus=${approvalStatus}&reviewerId=${reviewerId}&EnablePagination=true&PageNo=${pageIndex}&PageSize=${pageSize}`).pipe(map((res) => res));
  }
  approveORrejectNotification(data:any): Observable<any> {
    return this.http.put<any>(`api/reports/approval`,data).pipe(map((res) => res));
  }
  displayPDF(approvalId:any,approvalType:any): Observable<any> {
    return this.http.get<any>(`api/reports/approval/datalog/preview?approvalId=${approvalId}&approvalType=${approvalType}`).pipe(map((res) => res));
  }
  reportLoad(data:any): Observable<any> {
    return this.http.post<any>('api/reports/alarmlog/preview',data).pipe(map((res) => res));
  }
  sendForApprovalAlarmlog(data:any,type:any): Observable<any> {
    return this.http.post<any>(`api/reports/approval/${type}`,data).pipe(map((res) => res));
  }
  reportLoadAlarmAudit(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/alarmlog/preview',data).pipe(map((res) => res));
  }
  reportLoadEquipmentAudit(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/equipment/preview',data).pipe(map((res) => res));
  }
  reportLoadUserAudit(data:any): Observable<any> {
    return this.http.post<any>('api/reports/audit-trail/user/preview',data).pipe(map((res) => res));
  }
  reportLoadUserLog(data:any): Observable<any> {
    return this.http.post<any>('api/reports/userlog/preview',data).pipe(map((res) => res));
  }
  checkreportStatus(approvalId:any): Observable<any> {
    return this.http.get<any>(`api/reports/approval/status?approvalId=${approvalId}`).pipe(map((res) => res));
  }
}
