import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpService } from '../http/http.service';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  private pageInfo=new Subject<any>();
  private rolesChange=new Subject<any>();
  Notificationcounts=new Subject<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public snackBar: MatSnackBar,private http: HttpService,private _cookieService: CookieService) { }
 
  postpageInfo(val:any){
    this.pageInfo.next(val);
    
  }
  getpageInfo(){
    return this.pageInfo.asObservable();
  }
  sendNotifdicationCount(val:any){
    this.Notificationcounts.next(val);
    
  }
  snackBarDialogBox(status:any, msg:any) {
    if (status) {
      this.snackBar.open(msg, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['snack_bar'],
      });
    } else {
      this.snackBar.open(msg, '', {
        duration: 3000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['error_snack_bar'],
      });
    }
  }
  adduseraccount(data:any): Observable<any> {
    return this.http.post<any>('api/user',data).pipe(map((res) => res));
  }
  getAllUsers(top:any,skip:any,searchval:any,sort:any): Observable<any> {
    if(searchval != null && searchval !=''){
      if(sort.field !=null){
      return this.http.get<any>(`api/user?$filter=contains(name,'${searchval}') or contains(loginName,'${searchval}') or contains(email,'${searchval}')&EnablePagination=true&PageNo=${skip}&PageSize=${top}&$orderby=${sort.field} ${sort.direction}`).pipe(map((res) => res));

      }
      else{
      return this.http.get<any>(`api/user?$filter=contains(name,'${searchval}') or contains(loginName,'${searchval}') or contains(email,'${searchval}')&EnablePagination=true&PageNo=${skip}&PageSize=${top}`).pipe(map((res) => res));
      }
    }
    else{
      if(sort.field !=null){
      return this.http.get<any>(`api/user?EnablePagination=true&PageNo=${skip}&PageSize=${top}&$orderby=${sort.field} ${sort.direction}`).pipe(map((res) => res));

      }
      else{
        return this.http.get<any>(`api/user?EnablePagination=true&PageNo=${skip}&PageSize=${top}`).pipe(map((res) => res));
      }

    }
    
  }
  updateUseraccount(data:any): Observable<any> {
    return this.http.put<any>('api/user',data).pipe(map((res) => res));
  }
  getUserRoles(): Observable<any> {
    return this.http.get<any>('api/role').pipe(map((res) => res));
  }
  getReports(): Observable<any> {
    return this.http.get<any>('api/reportconfig/1').pipe(map((res) => res));
  }
  updateReporterHeader(data:any): Observable<any> {
    return this.http.put<any>('api/reportconfig',data).pipe(map((res) => res));
  }
  getSettings(): Observable<any> {
    return this.http.get<any>('api/settings').pipe(map((res) => res));
  }
  updateSettings(data:any,key:any): Observable<any> {
    return this.http.put<any>(`api/settings/${key}`,data).pipe(map((res) => res));
  }
  getRolesandPermission(searchKey:any): Observable<any> {
    if(searchKey !=null && searchKey != ''){
      return this.http.get<any>(`api/usermenu/menu?SearchKey=${searchKey}`).pipe(map((res) => res));
    }
    else{
      return this.http.get<any>('api/usermenu/permissions').pipe(map((res) => res));
    }
    
  }
  updateRolesAndPermission(data:any): Observable<any> {
   
    data=JSON.parse(data)
   
    data.forEach((item:any)=>{
      if(item.rolePermission[0].active=='partial'){
        item.rolePermission[0].active=true;
      }
      if(item.rolePermission[1].active=='partial'){
        item.rolePermission[1].active=true;
      }
      if(item.rolePermission[2].active=='partial'){
        item.rolePermission[2].active=true;
      }
      if(item.rolePermission[3].active=='partial'){
        item.rolePermission[3].active=true;
      }
    })
    return this.http.put<any>(`api/usermenu/permissions`,data).pipe(map((res) => res));
  }
  reAuthenictae(data:any): Observable<any> {
    return this.http.post<any>('api/user/re-authenticate',data).pipe(map((res) => res));
  }
  reSetpassword(data:any): Observable<any> {
    return this.http.put<any>('api/user/reset-password',data).pipe(map((res) => res));
  }
  changePassword(data:any): Observable<any> {
    return this.http.put<any>('api/user/change-password',data).pipe(map((res) => res));
  }
  roleschanged(val:any){
    this.rolesChange.next(val);
    
  }
  roleschangedAlert(){
    return this.rolesChange.asObservable();
  }
  forgotPasswordchanged(data:any): Observable<any> {
    return this.http.put<any>('api/user/forgot-password',data).pipe(map((res) => res));
  }
  getAllusersList(): Observable<any> {
    return this.http.get<any>('api/user?EnablePagination=false').pipe(map((res) => res));
  }
  getnotificationCount(): Observable<any> {
    return this.http.get<any>('api/notifications/count').pipe(map((res) => res));
  }
  changePasswordByUser(data:any): Observable<any> {
    return this.http.put<any>('api/user/update-password',data).pipe(map((res) => res));
  }
}
