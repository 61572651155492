import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { ResetPasswordComponent } from '../../administration-module/reset-password/reset-password.component';

@Component({
  selector: 'app-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.scss']
})
export class ConfirmationWindowComponent implements OnInit {

  form:FormGroup
  hide:boolean=true
  hideSecond:boolean=true
  constructor(private fb:FormBuilder,private dialog:MatDialogRef<ConfirmationWindowComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private admin:AdministrationService) { 
    this.form = this.fb.group({ 
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required ,
        ])),
        remarks: new FormControl(
          null,
          Validators.compose([
            Validators.required ,
          ])),
      })
  }

  ngOnInit(): void {
   
   
  }
  closewindow(){
  this.dialog.close(false)
  }
  save(){
let data={password:this.form.controls['password'].value}
    this.admin.reAuthenictae(data).subscribe(res=>{
if(res.result){
 this.dialog.close({status:true,comment:this.form.controls['remarks'].value})
}
    })
  }

}
