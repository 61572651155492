
<div class="col-md-12 fileviewer_heading">
    <div class="col-md-6">Report</div>
    
    <div class="col-md-6 close_icon"><img src="assets/icons/close.svg" (click)="close()"></div>
    </div>
<div class="row" >
    <mat-card id="fileviewer">
   
   
    <form [formGroup]="form" >
        <div class="create_divs" >
   
     
      <iframe src="" type="application/pdf" width="100%" height="100%" [ngStyle]="{'display': loader ? 'none' : 'block'}">
</iframe> 
      
<ngx-skeleton-loader class="sklton" *ngIf="loader" [theme]="{ 
    'border-radius': '5px',
     height: '5%',            
     border: '1px solid white',
     top: '10px'
   
  }" appearance="line" count="12"></ngx-skeleton-loader>

       

    </div>
    <div class="row col-md-12 p-l-r canclel_and_save_btn" *ngIf="IspreviewORNot">
        <div class="btn_form"  *ngIf="this.data['purpose'] == 'approve'">
            <div class="cancel_outer">
                
                <button class="cancel" (click)="rejectNotification()" *ngIf="!reject"><img src="assets/icons/reject.svg" class="margin_img">Reject</button>
                <button class="cancel reject_btn" *ngIf="reject" > <div class="lds-ring rejects"><div></div><div></div><div></div><div></div></div></button>
            
            </div>
            <div class="save_outer">
                <button class="save" (click)="approveNotification()" *ngIf="!approve"> <img src="assets/icons/approve.svg" class="margin_img">Approve</button>
                <button class="save approve_btn" *ngIf="approve" > <div class="lds-ring approves"><div></div><div></div><div></div><div></div></div></button>
                
            </div>
        </div>



        <div class="btn_form" *ngIf="this.data['purpose'] == 'preview'">
            <div class="save_outer">
                
                <button class="save" (click)="downloadpdfReport()">Download</button>
            
            </div>
           
        </div>

       
    </div>
    </form>
</mat-card>
    </div>
   