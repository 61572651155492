import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AdministrationService } from '../services/administration/administration.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private adminService:AdministrationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   
    return next.handle(request)
    .pipe(
       
        catchError((err: HttpErrorResponse) => {
          let error;

                switch (err.status) {
                  case 400:
                    error = {
                      status: err.error['message'].code,
                      message: err.error['message'].description,
                    };
                     this.adminService.snackBarDialogBox(false, err.error['message'].description);
      
                    break;
                  case 401:
                    error = {
                      status: err.status,
                      message: err.message,
                    };
                   // this.adminService.snackBarDialogBox(false, err.error['message'].description);
                    break;
                  case 403:
                    error = {
                      status: err.status,
                      message: err.message,
                    };
                    // this.adminService.snackBarDialogBox(false, err.error['message'].description);
                    break;
                  case 500:
                    error = {
                      status: err.status,
                      message: err.message,
                    };
      
                     this.adminService.snackBarDialogBox(false, 'Server issue! Please try after some time');
                    break;
                  case 406:
                    error = {
                      status: err.status,
                      message: err.message,
                    };
                    break;                  
                  default:
                    error = {
                      status: err.status,
                      message: err.message,
                    };
                     this.adminService.snackBarDialogBox(false, 'Connectivity Issue ');
                    break;
                }
            return throwError(error);
        })
    )
  }
}
