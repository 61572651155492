import {  Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { fromEvent, Subscription} from 'rxjs';
import { AdministrationService } from 'src/app/services/administration/administration.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
 
})
export class SidebarComponent implements OnInit {
  menuItems:any=[];
  submenupanelHead:any;
  submenuList:any=[];
  selectedMenu:any=0;
  collapsez:any=true;
  @Output() collapsed = new EventEmitter<any>();
  @Input() mobileviewenabled:any;
  @Input() index:any;
  showORhidesubmenu:boolean=false;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;
  @ViewChild('side') side: ElementRef | undefined;
  @Input() height:any;
  rols!:Subscription
  loader:any=false
  constructor(private _cookieService: CookieService,private router:Router,private auth:AuthService,private admin:AdministrationService) { }

  ngOnInit(): void {
  this.loadmenus();
  this.rols=this.admin.roleschangedAlert().subscribe(res=>{
if(res){
  this.loadmenus();
}
 })
  }
 
  loadmenus(){
    this.loader=true
    this.auth.getMenus(this._cookieService.get('userId')).subscribe(res=>{
      this.loader=false
      if(res.isSuccess){
       this.menuItems=res.result;
       this.index=this._cookieService.get('menuName');
       this.ngOnChanges();
     
       

      }

    })
   
  }
  ngAfterViewInit() {
    var contentHeight = this.side?.nativeElement.offsetHeight;
 
    this.height=this.height - contentHeight
}
  loadsubmenus(submenuitem:any,i:any){
   
   
    if(submenuitem.subMenu.length > 0){
      this.SubmenuOpend(submenuitem)       
    }
    else{
      this.showORhidesubmenu=false;
    }
    
  }

  loadsubmenusselection(submenuitem:any,i:any){
    this.selectedMenu=i; 
    this.showORhidesubmenu=false;
    
  }
  ngOnChanges(): void{
    this.menuItems.forEach((item:any,i:any)=>{
      if(item.menuName == this.index){
        this.selectedMenu=i;
      }
   })
  
}
  loadsubmenusover(submenuitem:any,i:any){
    this.selectedMenu=i;
  }
  closematNav(){
   this.showORhidesubmenu=false;
  }
  logout(){
    this.auth.signout().subscribe(res=>{
      if(res.isSuccess){
      this. _cookieService.remove('userId');
      this._cookieService.remove('menuName');
      this._cookieService.remove('roleId'); 
   this.router.navigate(['authentication']);
      }
    })
  
  }
  collapse(){
    
    if(!this.mobileviewenabled){
      var r =<HTMLElement> document.querySelector(':root');   
      this.collapsez=!this.collapsez;
      
      this.collapsed.emit(!this.collapsez);
    }
    else{
      var contentHeight = this.side?.nativeElement.offsetHeight;
   
    this.height=this.height - contentHeight
      this.collapsed.emit('mobenabled');
    }
    
    //this.menuItems=[];
  }
  opensubmenu(){
    this.showORhidesubmenu=false;
    if(this.mobileviewenabled){
      this.collapsed.emit('mobenabled');
    }
  }
  SubmenuOpend(submenuitem:any){
    setTimeout(()=>{
    const obs = fromEvent(document, 'click')
   
   let sub= obs.subscribe(event => {
      if( this.showORhidesubmenu ){
      if((<HTMLElement>event.target).id != 'submenuPanel'){
      this.showORhidesubmenu=false
      sub.unsubscribe();
      }
    }
    else{
      sub.unsubscribe();
    }
        
        });
        this.submenuList=submenuitem.subMenu;
        this.submenupanelHead=submenuitem.menuName;
        this.showORhidesubmenu=true;
        if(this.mobileviewenabled){ 
          this.height=this.height - 78
        }
      },10)
     
   
  }
  
}
