import { throwError as observableThrowError, Observable, EMPTY } from 'rxjs';
import { Injectable, Output } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router';
import { ApiResponse } from '../../models/api-response.model';
import { SessionTimeoutComponent } from 'src/app/components/home/common-module/session-timeout/session-timeout.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private config: ConfigService,
    private dialog:MatDialog,
    private _cookieService:CookieService
   
  ) {}
  get<T>(url: string): Observable<ApiResponse<T>> {
    const completeUrl = this.config.baseUrl + url;
    return this.http.get<ApiResponse<T>>(completeUrl).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e, this))
    );
  }

  post<T>(url: string, body: any): Observable<ApiResponse<T>> {
    const completeUrl = this.config.baseUrl + url;
    return this.http.post<ApiResponse<T>>(completeUrl, body).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e, this))
    );
  }

  put<T>(url: string, body: string): Observable<ApiResponse<T>> {
    const completeUrl = this.config.baseUrl + url;
    return this.http.put<ApiResponse<T>>(completeUrl, body).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e, this))
    );
  }

  delete<T>(url: string): Observable<ApiResponse<T>> {
    const completeUrl = this.config.baseUrl + url;
    return this.http.delete<ApiResponse<T>>(completeUrl).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e, this))
    );
  }

  patch<T>(url: string, body: string): Observable<ApiResponse<T>> {
    const completeUrl = this.config.baseUrl + url;
    return this.http.patch<ApiResponse<T>>(completeUrl, body).pipe(
      map((res) => res),
      catchError((e) => this.handleError(e, this))
    );
  }
  
  private handleError(error: any, thisObj: any) {
  if(error.status == 401){
    this.SessionTimeoutScreen();
  }
      return observableThrowError(error);
  
  }
  SessionTimeoutScreen() {
   if(this. _cookieService.get('userId')){
    this. _cookieService.remove('userId');
    const dialogRef = this.dialog.open(SessionTimeoutComponent, {
      width: '545px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        this.dialog.closeAll()
      this.signouts().subscribe(res=>{
        if(res.isSuccess){
     this.router.navigate(['authentication']);
        }
      })
    }
    });
  }
}
  signouts(): Observable<any> {
    let data={

    }
    return this.http.post<any>('api/auth/signout',data).pipe(map((res) => res));
  }

}
