import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SessionTimeoutComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}
  confirmCurrentItem() {
    this.dialogRef.close(true);
  }

}
